<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
        >
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.canchas.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.sucursales.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.sucursales.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.sucursal_id"
                        :placeholder="$t('app.sucursales.single')"
                        :items="sucursales"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      ></v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.name')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.nombre"
                        :placeholder="$t('app.headers.name')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.description') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.description')"
                      rules="required"
                    >
                      <v-textarea
                        v-model="item.descripcion"
                        :placeholder="$t('app.headers.description')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name: "canchasAdd",
      data () {
        return {
          dialog: false,
          item: {
            descripcion: '',
            sucursal_id: null,
            duracion_juego: ''
          },
          sucursales: [],
          datePickerModalStart: false,
          datePickerModalEnd: false,
        }
      },
      mounted() {
        EventBus.$on('canchas-add', async () => {
          this.toggleLoader()
          this.sucursales = await this.getBranches()

          this.toggleLoader()
          this.dialog = true
        })
      },
      methods: {
          async saveItem () {
            this.$refs.observer.validate()
              .then(async success => {
                if (!success) return

                this.toggleLoader()

                await this.$http.post(route('v1/field'), this.item)
                .then(response => {
                  if (response.body.code === 200) {
                    this.processSuccess(response)
                    this.item = {
                      descripcion: '',
                      sucursal_id: null,
                    }

                    requestAnimationFrame(() => {
                      this.$refs.observer.reset()
                    })

                    this.dialog = false
                    EventBus.$emit('reload-items')
                  } else {
                    this.processError(response)
                  }
                }, error => {
                  this.processError(error)
                })

                this.toggleLoader()
              })
          },
      }
    }
</script>

<style scoped>

</style>
